export const initAppBuildKey = 'INIT_APP_BUILD_NUMBER'
export const initAppVersionKey = 'INIT_APP_VERSION_NUMBER'
export const localVersionNumber = '1.5.9'
export const localBuildNumber = '1531'

const emmaDarkGreen = `var(--primary-color)`
const emmaLightGreen = `var(--secondary-color)`
const emmaYellow = `var(--warning-color)`
const emmaLime = `#B8F0C3`
const emmaAlmostBlackColor = `#A6A6A6`
const emmaDarkGrayColor = `#D2D4D6`

const MAIN_WEBSITE = `https://www.emma-live.com/`

const prodPdfFileDir = `https://system.emma-live.com/portal/hwdwxbsudb/UserFiles/pdf/`

export default {
	MAIN_WEBSITE,
	primaryColor: 'var(--primary-color)',
	emmaColor: 'var(--primary-color)', // emmaDarkGreen
	buttonBgColor: '',
	paginationPageSizeOptions: [25, 100, 250, 500],
	debounceTime: 500,
	fullHeightWithoutFooter: 'calc(100vh - 245px)',
	emmaLightGreen,
	emmaDarkGreen,
	emmaYellow,
	emmaGold: emmaYellow,
	emmaLime,
	emmaDarkGrayColor,
	emmaAlmostBlackColor,
	commonBorderColor: 'var(--border-color)',
	prodPdfFileDir,
	commonBulkInstructionsPDF: `${prodPdfFileDir}EMMA-AuctionUpload_Instructions-120723.pdf`,
	stickyOffsetHeader: 104,
	eventBadgeColors: {
		live: emmaLightGreen,
		upcoming: `rgb(240, 188, 66)`,
		completed: `rgb(48, 48, 57)`,
		all: emmaDarkGreen,
	},
	hiddenStyles: {
		display: 'inline',
		width: 0,
		height: 0,
		opacity: 0,
		position: 'absolute',
		top: 0,
		left: 0,
		zIndex: -9,
	},
	formSectionBgColor: 'var(--body-bg-color)',
}
